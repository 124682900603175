<template>
  <div class="app-container">
    <el-tabs v-model="activeNames" @tab-click="handleClick" v-loading="loading">
      <el-tab-pane label="List Filters" name="1">
        <el-table :data="userConditionData" style="width: 100%">
          <el-table-column label="Display Name">
            <template slot-scope="{row}">
              <template v-if="row.editMode">
                <el-input v-model="row.displayName" class="edit-input" size="small" />
              </template>
              <span v-else>{{ row.displayName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Code">
            <template slot-scope="{row}">
              <template v-if="row.editMode">
                <el-input v-model="row.codeName" class="edit-input" size="small" />
              </template>
              <span v-else>{{ row.codeName }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Description">
            <template slot-scope="{row}">
              <template v-if="row.editMode">
                <el-input v-model="row.desc" class="edit-input" size="small" />
              </template>
              <span v-else>{{ row.desc }}</span>
            </template>
          </el-table-column>
          <el-table-column label="Actions">
            <template slot-scope="{row}">
              <template v-if="row.editMode">
                <template v-if="row.updateMode">
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    disabled
                    @click="confirmEdit(row)"
                  >Update</el-button>
                  <el-button
                    class="cancel-btn"
                    size="small"
                    icon="el-icon-refresh"
                    type="warning"
                    disabled
                    @click="cancelEdit(row)"
                  >Cancel</el-button>
                </template>
                <template v-else>
                  <el-button
                    type="success"
                    size="small"
                    icon="el-icon-edit-outline"
                    @click="confirmEdit(row)"
                  >Update</el-button>
                  <el-button
                    class="cancel-btn"
                    size="small"
                    icon="el-icon-refresh"
                    type="warning"
                    @click="cancelEdit(row)"
                  >Cancel</el-button>
                </template>
              </template>
              <template v-else>
                <el-button
                  type="primary"
                  size="small"
                  icon="el-icon-edit"
                  @click="row.editMode = true"
                >Edit</el-button>
                <el-button
                  type="danger"
                  size="small"
                  icon="el-icon-delete"
                  @click="onDelete(row)"
                >Delete</el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
      </el-tab-pane>
      <el-tab-pane label="Add Filters" name="2">
        <el-form ref="form" :model="form" label-width="120px">
          <el-form-item label="Display name">
            <el-input v-model="form.displayName"></el-input>
          </el-form-item>
          <el-form-item label="Code Name">
            <el-input v-model="form.codeName"></el-input>
          </el-form-item>
          <el-form-item label="Description">
            <el-input v-model="form.desc"></el-input>
          </el-form-item>
          <el-form-item>
            <el-button type="primary" @click="onSubmit">Add Condition</el-button>
          </el-form-item>
        </el-form>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>


<script>
import { MessageBox, Message } from "element-ui";
import { Loading } from "element-ui";
import { isStringEmpty } from "@/utils/validate";
import {
  createUserCondition,
  getAllUserConditions,
  updateUserCondition,
  deleteUserCondition
} from "@/api/userconditions";

export default {
  name: "UserConditions",
  components: {},
  data() {
    return {
      form: {
        displayName: "",
        codeName: "",
        desc: ""
      },
      userConditionsData: [],
      loading: false,
      activeNames: "1"
    };
  },
  created() {
    this.getListUserConditions();
  },
  methods: {
    getListUserConditions() {
      this.loading = true;
      this.userConditionData = [];
      getAllUserConditions()
        .then(response => {
          response.forEach(i => {
            this.userConditionData.push({
              id: i.db_id,
              displayName: i.name,
              codeName: i.code,
              desc: i.description,
              editMode: false,
              updateMode: false,
              deleteMode: false,
              data: {
                name: i.name,
                code: i.code,
                description: i.description
              }
            });
          });
          this.loading = false;
        })
        .catch(error => {
          Message({
            message: "Can't fetch list User Conditions: " + error,
            type: "error",
            duration: 5 * 1000
          });
          this.loading = false;
        });
    },

    onSubmit() {
      if (isStringEmpty(this.form.displayName)) {
        Message({
          message: "Name is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      if (isStringEmpty(this.form.codeName)) {
        Message({
          message: "Code is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      if (isStringEmpty(this.form.desc)) {
        Message({
          message: "Description is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      let loadingInstance = Loading.service({
        lock: true,
        text: "Create user condition...",
        spinner: "el-icon-loading",
        background: "rgba(0, 0, 0, 0.7)"
      });

      createUserCondition({
        name: this.form.displayName,
        code: this.form.codeName,
        description: this.form.desc
      })
        .then(response => {
          loadingInstance.close();
          Message({
            message: response,
            type: "success",
            duration: 5 * 1000
          });

          this.form.displayName = "";
          this.form.codeName = "";
          this.form.desc = "";
          this.getListUserConditions();
        })
        .catch(error => {
          loadingInstance.close();
        });
    },
    confirmEdit(row) {
      if (isStringEmpty(row.displayName)) {
        Message({
          message: "Name is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      if (isStringEmpty(row.codeName)) {
        Message({
          message: "Code is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      if (isStringEmpty(row.desc)) {
        Message({
          message: "Description is empty",
          type: "error",
          duration: 5 * 1000
        });
        return;
      }

      row.updateMode = true;

      updateUserCondition(row.id, {
        name: row.displayName,
        code: row.codeName,
        description: row.desc
      })
        .then(response => {
          Message({
            message: response,
            type: "success",
            duration: 5 * 1000
          });

          row.data.name = row.displayName;
          row.data.code = row.codeName;
          row.data.description = row.desc;

          row.updateMode = false;
          row.editMode = false;
        })
        .catch(error => {
          row.updateMode = false;
          row.editMode = false;
        });
    },
    cancelEdit(row) {
      row.displayName = row.data.name;
      row.codeName = row.data.code;
      row.desc = row.data.description;
      row.editMode = false;
    },
    onDelete(row) {
      MessageBox.confirm(
        "Do you confirm delete this User Condition: " + row.displayName + "?",
        "Confirm delete",
        {
          confirmButtonText: "Delete",
          cancelButtonText: "Cancel",
          type: "warning"
        }
      ).then(() => {
        row.deleteMode = true;
        deleteUserCondition(row.id)
          .then(response => {
            Message({
              message: response,
              type: "success",
              duration: 5 * 1000
            });
            this.itemData = this.itemData.filter(function(ele) {
              return ele.id != row.id;
            });
          })
          .catch(error => {
            row.deleteMode = false;
          });
      });
    },
    handleClick() {}
  }
};
</script>

<style lang="scss" scoped>
.userconditions-container {
  margin: 50px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
}
</style>
